import _helper from "../helper";
import _ActionSet from "../ActionSet";
import _types from "../types";
var exports = {};
var __extends = exports && exports.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Loading = exports.stop = exports.start = exports.Action = void 0;
var helper_1 = _helper;
var ActionSet_1 = _ActionSet;
var types_1 = _types;
var Action;
(function (Action) {
  Action["START"] = "APP::LOADING::START";
  Action["STOP"] = "APP::LOADING::STOP";
})(Action = exports.Action || (exports.Action = {}));
function start(payload) {
  return helper_1.actionWrapper({
    payload: payload,
    group: types_1.Group.Loading,
    type: Action.START
  });
}
exports.start = start;
function stop(payload) {
  return helper_1.actionWrapper({
    payload: payload,
    group: types_1.Group.Loading,
    type: Action.STOP
  });
}
exports.stop = stop;
var Loading = /** @class */function (_super) {
  __extends(Loading, _super);
  function Loading(app) {
    return _super.call(this, app, types_1.Group.Loading, types_1.Group.Loading) || this;
  }
  Object.defineProperty(Loading.prototype, "payload", {
    get: function () {
      return {
        id: this.id
      };
    },
    enumerable: false,
    configurable: true
  });
  Loading.prototype.dispatch = function (action) {
    switch (action) {
      case Action.START:
        this.app.dispatch(start(this.payload));
        break;
      case Action.STOP:
        this.app.dispatch(stop(this.payload));
        break;
    }
    return this;
  };
  return Loading;
}(ActionSet_1.ActionSet);
exports.Loading = Loading;
export default exports;
export const __esModule = exports.__esModule;
const _Loading = exports.Loading,
  _stop = exports.stop,
  _start = exports.start,
  _Action = exports.Action;
export { _Loading as Loading, _stop as stop, _start as start, _Action as Action };